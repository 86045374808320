<template>
  <div-wrapper className="register-cnt">
    <header class="inner-head">
      <!-- <span-wrapper className="icon-btn goback" @handleClick="$emit('go-back')">
        <back-icon />
      </span-wrapper> -->
      <h1>New Registration</h1>
    </header>
    <section class="mid-cnt">
      <div-wrapper className="title-bar" :txt="$t('_personalDetails')" />
      <div-wrapper id="personal" className="form-box">
        <div-wrapper className="field-row">
          <input-wrapper
            name="name"
            i-type="text"
            className="input-style"
            :placeholder="$t('_writeYourFullNameHere')"
            :value="form.name"
            @input="getInput"
          />
          <error-text :txt="inputError['name']" />
        </div-wrapper>
        <div-wrapper className="field-row">
          <input-wrapper
            name="city"
            i-type="text"
            className="input-style"
            :placeholder="$t('_enterYourCity')"
            :value="form.city"
            @input="getInput"
          />
          <error-text :txt="inputError['city']" />
        </div-wrapper>
        <div-wrapper className="field-row">
          <input-wrapper
            name="state"
            i-type="text"
            className="input-style"
            :placeholder="$t('_enterYourState')"
            :value="form.state"
            @input="getInput"
          />
          <error-text :txt="inputError['state']" />
        </div-wrapper>
        <div-wrapper className="field-row">
          <div-wrapper className="rel-box">
            <input-wrapper
              name="mobileNo"
              i-type="number"
              className="input-style"
              :placeholder="$t('_yourDigitmobileNumber')"
              :value="form.mobileNo"
              @input="getInput"
            />
            <error-text :txt="inputError['mobileNo']" />
          </div-wrapper>
        </div-wrapper>
        <div-wrapper
          className="field-row"
          v-if="this.getUserInfo && this.getUserInfo['latlong']"
        >
          <span class="button" @click="openLocation()">update location </span>
        </div-wrapper>
        <div-wrapper className="field-row">
          <input-wrapper
            name="referralId"
            i-type="text"
            className="input-style"
            :placeholder="$t('_enterReferralCode')"
            :value="form.referralId"
            @input="getInput"
          />
          <error-text :txt="inputError['referralId']" />
        </div-wrapper>
        <div-wrapper className="field-row">
          <text-area-wrapper
            name="address"
            className="textare-style"
            :placeholder="$t('_fullAddressData')"
            :value="form.address"
            @textarea="getInput"
          />
          <error-text :txt="inputError['address']" />
        </div-wrapper>
        <div-wrapper className="field-row">
          <input-wrapper
            name="pincode"
            i-type="text"
            className="input-style"
            :placeholder="$t('_enterPincode')"
            :value="form.pincode"
            @input="getInput"
          />
          <error-text :txt="inputError['pincode']" />
        </div-wrapper>
        <div-wrapper className="field-row">
          <div-wrapper className="pin-area">
            <ul v-if="selectedPincodes && selectedPincodes.length" class="chiplisting">
              <li
                v-for="(pincode, index) in selectedPincodes"
                :key="index"
              >
                <span class="text-label">{{ pincode }}</span>
                <span class="close" @click="removePincode(index)">
                  <close-icon />
                </span>
              </li>
            </ul>
            <input-wrapper
              name="supportedPincode"
              iType="number"
              id="text_val"
              :placeholder="$t('_typePinAndEnter')"
              @input="getSupportedPin"
            />
          </div-wrapper>
          <error-text :txt="inputError['supportedPincode']" />
        </div-wrapper>
      </div-wrapper>
      <!-- <div-wrapper className="title-bar" :txt="$t('_uploadDocument')" /> -->
      <!-- <div-wrapper id="docs" className="upload-box">
        <div-wrapper className="upload-panel">
          <h2>{{ $t("_panCard") }}</h2>
          <div-wrapper className="field-row">
            <input-wrapper
              name="pancardNumber"
              i-type="text"
              className="input-style"
              :placeholder="$t('_enterPanCardNumber')"
              :value="form.pancardNumber"
              @input="getInput"
            />
            <error-text :txt="inputError['pancardNumber']" />
          </div-wrapper>
          <div-wrapper className="upload-data">
            <div-wrapper className="upload-col">
              <div-wrapper className="upload-item">
                <div-wrapper className="upload-file">
                  <div-wrapper className="upload-icon">
                    <camera-icon />
                  </div-wrapper>
                  <p>{{ $t("_takePhotoAndUpload") }}</p>
                </div-wrapper>
                <file-input
                  :disabled="getUserInfo.pancardNumberProof"
                  name="pancardNumberProof"
                  className="inputfile"
                  @fileInput="getFile"
                />
              </div-wrapper>
              <div-wrapper className="file-uploaded">
                <error-text :txt="inputError['pancardNumberProof']" />
                <div-wrapper
                  v-if="form.pancardNumberProof"
                  className="uploaded-item"
                >
                  <image-comp :url="form.pancardNumberProof" />
                </div-wrapper>
                <div-wrapper className="text-view">
                  <Span v-if="form.pancardNumberProof" :txt="$t('_zoom')" />
                </div-wrapper>
              </div-wrapper>
            </div-wrapper>
          </div-wrapper>
        </div-wrapper>
        <div-wrapper className="upload-panel adhar-pnl">
          <h2>{{ $t("_aadharCard") }}</h2>
          <div-wrapper className="field-row">
            <input-wrapper
              :disabled="getUserInfo.aadhaarNumber"
              name="aadhaarNumber"
              i-type="text"
              className="input-style"
              :placeholder="$t('_enterAadharCardNumber')"
              :value="form.aadhaarNumber"
              @input="getInput"
            />
            <error-text :txt="inputError['aadhaarNumber']" />
          </div-wrapper>
          <div-wrapper className="upload-data">
            <div-wrapper className="upload-col">
              <div-wrapper className="upload-item">
                <div-wrapper className="upload-file">
                  <div-wrapper className="upload-icon">
                    <camera-icon />
                  </div-wrapper>
                  <p>{{ $t("_takeFurtherPhotos") }}</p>
                </div-wrapper>
                <file-input
                  :disabled="getUserInfo.aadhaarNumberProof"
                  name="aadhaarNumberProof"
                  className="inputfile"
                  @fileInput="getFile"
                />
              </div-wrapper>
              <div-wrapper className="file-uploaded" style="">
                <error-text :txt="inputError['aadhaarNumberProof']" />
                <div-wrapper
                  v-if="form.aadhaarNumberProof"
                  className="uploaded-item"
                >
                  <image-comp :url="form.aadhaarNumberProof" />
                </div-wrapper>
                <div-wrapper className="text-view">
                  <span-wrapper
                    v-if="form.aadhaarNumberProof"
                    :txt="$t('_zoom')"
                  />
                </div-wrapper>
              </div-wrapper>
            </div-wrapper>
            <div-wrapper className="upload-col">
              <div-wrapper className="upload-item">
                <div-wrapper className="upload-file">
                  <div-wrapper className="upload-icon">
                    <camera-icon />
                  </div-wrapper>
                  <p>{{ $t("_snapBack") }}</p>
                </div-wrapper>
                <file-input
                  :disabled="getUserInfo.aadhaarCardRear"
                  name="aadhaarCardRear"
                  className="inputfile"
                  @fileInput="getFile"
                />
              </div-wrapper>
              <div-wrapper className="file-uploaded">
                <error-text :txt="inputError['aadhaarCardRear']" />
                <div-wrapper
                  v-if="form.aadhaarCardRear"
                  className="uploaded-item"
                >
                  <image-comp :url="form.aadhaarCardRear" />
                </div-wrapper>
                <div-wrapper className="text-view">
                  <span-wrapper
                    v-if="form.aadhaarCardRear"
                    :txt="$t('_zoom')"
                  />
                </div-wrapper>
              </div-wrapper>
            </div-wrapper>
          </div-wrapper>
        </div-wrapper>
      </div-wrapper> -->
    </section>
    <footer class="footer-bottom">
      <button-wrapper
        :disabled="isButtonDisable"
        className="button"
        btnTxt="Submit"
        @buttonClick="validateForm"
      />
    </footer>
  </div-wrapper>
</template>
<script>
import { mapGetters } from "vuex";

import { validateRegistrationInputs } from "@/utils/Validator";
import { uploadImageFile } from "@/utils/Helper";
import DivWrapper from "@/components/form/DivWrapper";
// import ImageComp from "@/components/form/ImgWrapper";
// import CameraIcon from "@/components/svgIcons/CameraIcon";
// import FileInput from "@/components/form/FileInput";
import InputWrapper from "@/components/form/InputWrapper";
import ErrorText from "@/components/commonUI/ErrorText";
// import SpanWrapper from "@/components/form/SpanWrapper";
import TextAreaWrapper from "@/components/form/TextAreaWrapper";
import ButtonWrapper from "@/components/form/ButtonWrapper";
// import ApiRequest from '../../services/ApiRequest';
// import { BASE_API } from '../../utils/constants';
import { PINCODE_REGEX } from "../../utils/RegExpConst";
// import { GET } from '../../utils/HttpStatusCodeConst';
import { validate } from "../../utils/Validator";
import CloseIcon from '../svgIcons/CloseIcon.vue';

export default {
  name: "SignUp",
  components: {
    ButtonWrapper,
    TextAreaWrapper,
    // SpanWrapper,
    ErrorText,
    InputWrapper,
    DivWrapper,
    // CameraIcon,
    // ImageComp,
    // FileInput,
    CloseIcon,
    // BackIcon,
  },

  data() {
    return {
      selectedPincodes: [],
      isButtonDisable: false,
      form: {
        // aadhaarNumberProof: "string",
        // pancardNumberProof: "string",
        // aadhaarCardRear: null,
        // cscLicense: null,
        // aadhaarNumber: "",
        // pancardNumber: "",
        referralId: "",
        mobileNo: "",
        address: "",
        pincode: "",
        name: "",
        city: "",
        state: ""
      },
      inputError: {
        supportedPincode: ""
      }
    };
  },

  computed: {
    ...mapGetters({
      getUserInfo: "getUserInfo",
    }),
  },

  created() {
    for (let i in this.form) {
      this.$set(this.form, i, this.getUserInfo[i] || "");
    }
  },

  methods: {
    getSupportedPin(_event) {
      if (!validate(_event.target.value, PINCODE_REGEX)) {
        this.inputError["supportedPincode"] = this.$t("_enterSupportedPin");
        return;
      } else {
        if (this.selectedPincodes.includes(_event.target.value)) {
          this.inputError["supportedPincode"] = this.$t("_pincodeAlreadyUsed");
          return;
        }
        this.inputError["supportedPincode"] = "";
        this.selectedPincodes.push(_event.target.value.trim());
        document.getElementById("text_val").value = "";
        // this.form["supportedpincode"].push(_event.target.value.trim());
        // ApiRequest(BASE_API, `/pincodeused/${_event.target.value}`, GET)
        //   .then((_data) => {
        //     this.form["supportedPincode"] = "";
        //     if (_data.pincodeUsed) {
        //       this.inputError["supportedPincode"] = this.$t(
        //         "_pincodeAlreadyUsedByCourier"
        //       );
        //       return;
        //     }
        //     this.inputError["supportedPincode"] = "";
        //     this.form["supportedPincode"].push(_event.target.value.trim());
        //   })
        //   .catch(() => {
        //     this.inputError["supportedPincode"] = this.$t(
        //       "_pleaseEnterPincodeAgain"
        //     );
        //     this.form["supportedPincode"] = "";
        //   });
      }
    },
    removePincode(_index) {
      this.selectedPincodes.splice(_index, 1);
    },
    openLocation() {
      const tempArray = this.getUserInfo.latlong.split(",");
      const lat = parseFloat(tempArray[0]),
        lng = parseFloat(tempArray[1]);

      this.$router.push({
        name: "AddUserLocation",
        query: {
          lat,
          lng,
        },
      });
    },
    // isButtonDisable() {
    //   if (Number(this.$route.query.onboard)) {
    //     const {
    //       pancardNumberProof,
    //       aadhaarNumberProof,
    //       aadhaarCardRear,
    //       pancardNumber,
    //       aadhaarNumber,
    //     } = this.getUserInfo;
    //     return !(
    //       !pancardNumberProof ||
    //       !aadhaarNumberProof ||
    //       !aadhaarCardRear ||
    //       !pancardNumber ||
    //       !aadhaarNumber
    //     );
    //   }
    //   return false;
    // },

    disableFormSection(_eleId) {
      const fields = document.getElementById(_eleId).getElementsByTagName("*");
      for (let i = 0; i < fields.length; i++) {
        fields[i].disabled = true;
      }
    },

    // getFullAddress(_user) {
    //   const fullAddress =
    //     (_user.street || "") +
    //     " " +
    //     (_user.street2 || "") +
    //     " " +
    //     (_user.city || "") +
    //     " " +
    //     (_user.state || "") +
    //     " " +
    //     (_user.country || "");
    //   return fullAddress.trim().length ? fullAddress : "";
    // },

    getInput(_event) {
      this.form[_event.target.name] = _event.target.value;
    },

    getFile(_event) {
      if (
        _event.target.files[0].type === "image/png" ||
        _event.target.files[0].type === "image/jpeg"
      ) {
        let upload = uploadImageFile(_event.target.files[0]);
        upload.then((result) => {
          setTimeout(() => {
            this.form[_event.target.name] = result;
          }, 2000);
        });
      }
    },

    createURL(_file) {
      if (typeof _file === "string") {
        return _file;
      }
      return URL.createObjectURL(_file);
    },

    async validateForm() {
      let _this = this;
      this.inputError = validateRegistrationInputs(_this);
      console.log(this.inputError);
      if (Object.keys(this.inputError).length === 0 && this.inputError.constructor === Object) {
        let form = {
          ...this.form,
          supportedpincode: this.selectedPincodes
        }
        // this.isButtonDisable = true;
        this.$emit("submitRegistrationForm", form);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_registration.scss";
</style>
