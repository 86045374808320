<template>
  <div-wrapper className="onboarding-cnt">
    <header>
      <div-wrapper className="partner_logo">
        <img src="@/assets/images/partner91_textlogo.svg" alt="Partner91" />
      </div-wrapper>

      <div-wrapper className="text-link" @handleDivClick="$emit('skip')">
        {{ onBoardingObj.id === 2 ? $t('_done') : $t('_skip') }}
      </div-wrapper>
    </header>
    <div class="slide-box slide-img">
      <div-wrapper className="imgbox">
        <image-comp :imgName="onBoardingObj.img_name" />
      </div-wrapper>
    </div>
    <div class="slide-box slide-desc">
      <div class="info-box">
        <h1>{{ $t(onBoardingObj.heading) }}</h1>
        <p>
          {{ $t(onBoardingObj.desc[0]) }}
          <br v-if="onBoardingObj.id === 2" />

          <span-wrapper
            v-if="onBoardingObj.desc.length === 2"
          />
         
        </p>
      </div>
      <div-wrapper className="next-tab">
        <div-wrapper
          className="tab-item"
          @handleDivClick="$emit('next')"
          :txt="onBoardingObj.id === 2 ? $t('_letGo') : $t('_next')"
        />
      </div-wrapper>

      <div-wrapper className="ft-paging">
        <span-wrapper
          v-for="(i, index) in Array(3)"
          :key="index"
          :className="onBoardingObj.id === index ? 'active' : ''"
        />
      </div-wrapper>
    </div>
  </div-wrapper>
</template>

<script>
import DivWrapper from '@/components/form/DivWrapper';
import ImageComp from '@/components/form/ImgWrapper';
import SpanWrapper from '@/components/form/SpanWrapper';
export default {
  name: 'OnBoarding',
  props: {
    onBoardingObj: {
      type: Object,
      required: true,
    },
  },
  components: {
    DivWrapper,
    ImageComp,
    SpanWrapper,
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_onboarding.scss';
</style>
