<template>
  <div-wrapper>
    <on-boarding
      v-if="Number($route.query.onboard) < 3"
      @next="
        $router.push({
          name: 'RegistrationForm',
          query: { onboard: (Number($route.query.onboard) + 1).toString() },
        })
      "
      @skip="
        $router.push({
          name: 'RegistrationForm',
          query: { onboard: '3' },
        })
      "
      :on-boarding-obj="onBoarding[$route.query.onboard]"
    />
    <sign-up
      v-else
      @submitRegistrationForm="saveRegistrationData"
      @go-back="goBack"
    />
    <!--    <Popup :message="message" @handleClick="message = ''" :color="color" />-->
    <loader-wrapper v-if="isLoading" />
  </div-wrapper>
</template>

<script>
import OnBoarding from "@/components/registration-form/OnBoarding";
import { ONBOARDING } from "@/utils/OnBoardingConst";
import DivWrapper from "@/components/form/DivWrapper";
import SignUp from "@/components/registration-form/SignUp";
import ApiRequest from "@/services/ApiRequest";
import Axios from 'axios';
import { X_AUTH_ID } from '@/services/Session';
import { BASE_API } from "@/utils/constants";
import { POST } from "@/utils/HttpStatusCodeConst";
import Swal from "sweetalert2";
import LoaderWrapper from '../components/commonUI/LoaderWrapper.vue';
import { SessionHelper } from '@/services/Session';
export default {
  name: "RegistrationForm",
  components: { SignUp, DivWrapper, OnBoarding, LoaderWrapper },
  created() {
    if (this.$route.query.onboard === void 0)
      this.$router.push({ name: "RegistrationForm", query: { onboard: "0" } });
  },
  data() {
    return {
      index: this.$route.query.onboard || 0,
      onBoarding: ONBOARDING,
      isLoading: false
    };
  },
  methods: {
    goBack() {
      console.log("why you here, go Back bro");
    },
    async registerTask(taskId){
      const { data } = await Axios.put(
        BASE_API + '/partner91/task/update',
        { task_id: taskId, is_registered: true },
        {
          headers: {
            'X-Auth-Id': SessionHelper.getCookie(X_AUTH_ID),
          },
        }
      );
      this.$swal({
        icon: 'info',
        text: `पंजीकरण के लिए धन्यवाद, हमारे ग्राहक सहायता प्रतिनिधि जल्द ही आपसे संपर्क करेंगे। इस बीच आप होमपेज पर सूचीबद्ध मॉल91 पार्टनरशिप को देखें।`,
      }).then(() => {
          this.$router.push({ name: "Splash" });
      });


      if (data && data.result && data.result.status) {
        return true;
      }
      return false;

    },
    async saveRegistrationData(payload) {
      console.log("save Registration", payload);
      this.isLoading = true;
      // /partner91/create
      ApiRequest(BASE_API, `/partner91/create`, POST, payload)
        .then((response) => {
          // console.log(response.result);

          if(response && response.result){

            if(this.$route.query.taskid){
              this.registerTask(this.$route.query.taskid);
            }else{
              this.$router.push({ name: "Splash" })
            }

          }
          else{
            this.isLoading = false;
            Swal.fire({ text: this.$t("_pleaseTryAgain"), icon: "error" });
          }
          // if (response.result.is_payment_done){
          //   this.$router.push({ name: "Splash" })
          // }
          // else{
          //   this.$router.push({ name: "PaymentForm", query: {partner_id: response.result.partner_id} });
          // }
        })
        .catch(() => {
          this.isLoading = false;
           Swal.fire({ text: "Something went wrong", icon: "error" });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_common.scss";
</style>
