<template>
  <textarea
    :name="name"
    :class="className"
    v-model="content"
    @input="handleTextarea"
    :disabled="disabled"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  methods: {
    handleTextarea(e) {
      this.$emit("textarea", e);
    }
  }
};
</script>
