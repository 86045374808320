<template>
  <span-wrapper v-if="txt" :className="className" :txt="txt" />
</template>

<script>
import SpanWrapper from "@/components/form/SpanWrapper";
export default {
  components: {
    SpanWrapper,
  },
  props: {
    className: {
      type: String,
      default: "text-error",
    },
    txt: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.text-error {
  color: #fa6400;
  font-size: 0.75em;
  margin: 6px 0;
  display: block;
  text-transform: capitalize;
}
</style>
