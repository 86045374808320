export const ONBOARDING = [
  {
    id: 0,
    img_name: "register-now.svg",
    heading: "_needInformation",
    desc: ["_toBecomeOurPartner"],
  },
  {
    id: 1,
    img_name: "completed-task.svg",
    heading: "_investingFun",
    desc: ["_justDeposit", "_asSecurity"],
  },
  {
    id: 2,
    img_name: "extra-income.svg",
    heading: "_hassleFreeRegistration",
    desc: ["_registerWithUs", "_everyMonth"],
  },
];
