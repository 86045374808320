<template>
  <input
    :class="className"
    :name="name"
    :type="iType"
    v-model="content"
    :placeholder="placeholder"
    @input="handleInput"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    className: {
      type: String,
      default: "",
    },
    iType: {
      type: String,
      default: "text",
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e);
    },
  },
};
</script>
