var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div-wrapper',[(Number(_vm.$route.query.onboard) < 3)?_c('on-boarding',{attrs:{"on-boarding-obj":_vm.onBoarding[_vm.$route.query.onboard]},on:{"next":function($event){_vm.$router.push({
        name: 'RegistrationForm',
        query: { onboard: (Number(_vm.$route.query.onboard) + 1).toString() },
      })},"skip":function($event){return _vm.$router.push({
        name: 'RegistrationForm',
        query: { onboard: '3' },
      })}}}):_c('sign-up',{on:{"submitRegistrationForm":_vm.saveRegistrationData,"go-back":_vm.goBack}}),(_vm.isLoading)?_c('loader-wrapper'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }